import { pinyin } from "pinyin-pro"

export const convertToPinyin = (text) => {
    // Convert Chinese characters to Pinyin and join them into a string
    const pinyinArray = pinyin(text, { toneType: 'none', type: 'array' });
    return pinyinArray.join('');
};

export const inputDefault = {
    songName: '',
    singer: '',
    language: '',
    style: '',
    mark: '',
}

const localTest = false;
// Give up using this, switch to hardcode temporarily
// console.log('process.env.REACT_APP_API_URL:', process.env.REACT_APP_API_URL);

// TODO: modify the Hardcode the IP address
// export const appURL = process.env.REACT_APP_API_URL;
// export const appURL = 'http://localhost:3001' // Localhost testing
export const appURL = localTest ? 'http://localhost:3001' : 'https://shio.icu'