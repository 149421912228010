

import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { appURL } from '../constants';

import './uploadComp.css';
import HintSystem from '../hintBar';

function UploadComp() {
    const [text, setText] = useState(''); // text content
    const [name, setName] = useState(''); // user name

    const hintRef = React.useRef();

    const uploadText = async () => {
        if (name) {
            try {
                const response = await fetch(`${appURL}/upload`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({name, text})
                })
                if (response.ok) {
                    setText('');
                    setName('');
                    hintRef.current.showHint(`你的心意汐汐已经收到了哦(〃'▽'〃)~`)
                    
                } else {
                    console.error('uploadComp.js: Failed to create file');
                }
            } catch (error) {
                console.error('uploadComp.js: Error during text request:', error);
                // handle error if request fails
            }
        } else {
            hintRef.current.showHint(`要告诉汐汐你是谁哦(｀⌒´メ)`)
        }
    };

    return (
        <Box className='upload-container'>
            <TextField
                sx={{
                    width: '60%', /* Make the item fill the width of its container */
                    maxWidth: '900px', /* Center the song item and prevent it from being too wide */
                }}
                variant="outlined"
                fullWidth
                margin="normal"
                label="你是..."
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <TextField
                sx={{
                    width: '60%', /* Make the item fill the width of its container */
                    maxWidth: '900px', /* Center the song item and prevent it from being too wide */
                    maxHeight: '50vh'
                }}
                label="🦑有什么想对汐汐说的话嘛🦑"
                variant="outlined"
                multiline
                fullWidth
                margin="normal"
                maxRows={12}
                value={text}
                onChange={(e) => setText(e.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={uploadText}
                fullWidth
                sx={{
                    mt: 2,
                    width: '20%',
                    minWidth: '100px'
                }}
            >
                提交🥦
            </Button>
            <HintSystem ref={hintRef}/>
        </Box>
    );
}

export default UploadComp;