import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './pagination.css';


// TODO: If the total page is less than current page set to current page
function CustomPagination({ totalPages, currentPage, onPageChange }) {
    const handleChange = (event, value) => {
        onPageChange(value);
    };

    return (
        <Stack spacing={2} alignItems="center" className='pagination'
            sx={{
                mt: 1,  // Adds margin top
                mb: 1   // Adds margin bottom
            }}>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                siblingCount={1} // Number of sibling pages to show on each side
                boundaryCount={1} // Number of pages to show at the start/end
                variant="outlined" // Style variant
                shape="rounded" // Shape of the pagination buttons
                hideNextButton={true} // Show or hide the next button
                hidePrevButton={true} // Show or hide the previous button
            />
        </Stack>
    );
}

export default CustomPagination;



// function Pagination({ totalPages, currentPage, onPageChange }) {
//     // Generate an array for the number of pages [1, 2, ..., totalPages]
//     const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
//     return (
//         <div className="pagination">
//             {/* Previous Button */}
//             <button
//                 onClick={() => onPageChange(currentPage - 1)}
//                 disabled={currentPage === 1}
//             >
//                 Previous
//             </button>

//             {/* Page Numbers */}
//             {pages.map((page) => (
//                 <button
//                     key={page}
//                     className={page === currentPage ? 'active' : ''}
//                     onClick={() => onPageChange(page)}
//                 >
//                     {page}
//                 </button>
//             ))}

//             {/* Next Button */}
//             <button
//                 onClick={() => onPageChange(currentPage + 1)}
//                 disabled={currentPage === totalPages}
//             >
//                 Next
//             </button>
//         </div>
//     );
// }

// export default Pagination;