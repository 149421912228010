import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Button } from '@mui/material';

import CustomPagination from './pagination.js';
import Controls from './control.js';
import NewEntryField from './newEntryField.js'



import "./songList.css";
import { inputDefault } from '../constants.js';
import { appURL } from '../constants.js';

import HintSystem from '../hintBar.js';

function SongList({ login, setLogin }) {

    const hintRef = React.useRef();

    // Control
    const [insert, setInsert] = useState(false); // toggle insert button
    const [remove, setRemove] = useState(false); // toggle remove button
    // 5 element json stores the new entry field
    const [newEntry, setNewEntry] = useState(null);
    // array of id of selected songs, used in remove (multiple element) and edit (single)
    const [selectedEntries, setSelectedEntries] = useState([]);
    const [edit, setEdit] = useState(false); // toggle edit button
    const [search, setSearch] = useState(false); // toggle search button
    const [searchBar, setSearchBar] = useState(''); // contents in the search bar


    // variable to store a list of songs, in this case a page
    // array
    const [songs, setSongs] = useState([]);
    // songList part
    // The total amount of songs. Should be modified after the fetch count
    // Seems not to be using since we 
    const [totalSongs, setTotalSongs] = useState(0);
    // Total pages, calculated after fetch
    // int
    const [pages, setPages] = useState(0);
    // Current pages
    const [currentPage, setCurrentPage] = useState(1);
    // Should be matching with the constant in app.js!
    const songsPerPage = 50;

    const controlProps = {
        insert,
        setInsert,
        remove,
        setRemove,
        newEntry,
        setNewEntry,
        edit,
        setEdit,
        selectedEntries,
        setSelectedEntries,
        totalSongs,
        setTotalSongs,
        search,
        setSearch,
        searchBar,
        setSearchBar,
        login,
        setLogin,
    };

    // Handle entry click when in remove mode
    const handleEntryClick = (song) => {
        if (remove) { // Check if removeEntry is true
            setSelectedEntries((prev) =>
                prev.includes(song._id)
                    ? prev.filter((entryId) => entryId !== song._id) // Remove if already selected
                    : [...prev, song._id] // Add if not selected
            );
        } else if (edit) { // Check if edit entry is true
            // console.log(selectedEntries);
            // console.log(song._id);
            setSelectedEntries((prev) =>
                prev.includes(song._id)
                    ? []
                    : [song._id]
            ); // Set the current selected to the current song
            setNewEntry(() =>
                (selectedEntries[0] === song._id)
                    // If we have newly selected something, update the input field
                    ? inputDefault
                    // Otherwise clear the input field
                    : {
                        songName: song.songName,
                        singer: song.singer,
                        language: song.language,
                        style: song.style,
                        mark: song.mark,
                    }
            )
        } else {
            navigator.clipboard.writeText(`点歌 ${song.songName}`)
                .then(() => console.log(`${song.songName} copied to clipboard`))
                .catch((err) => console.error('Failed to copy text: ', err));

            hintRef.current.showHint(`(σ°∀°)σ..:*☆「${song.songName}」已经复制到你的剪切板辣~`);
        }
    };

    useEffect(() => {
        const searchQuery = search ? encodeURIComponent(searchBar) : '';
        const url = `${appURL}/songList/len?query=${searchQuery}`;
        // Fetch the total number of songs from the backend
        // The port for the server: 3001
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                // console.log(`songList.js/SongList: totalSongs: ${totalSongs}`, login);

                setTotalSongs(data.len);

                // Calculate the number of pages needed
                const pageCount = Math.ceil(totalSongs / songsPerPage);
                // console.log(`songList.js/SongList: totalSongs: ${data.len}`)
                setPages(pageCount);
            })
            .catch((error) => console.error('Error fetching total count:', error));
    }, [totalSongs, search, searchBar]);

    useEffect(() => {
        if (pages < currentPage) {
            console.log(`songList.js: page changed, ${currentPage} > ${pages} , refresh`);
            setCurrentPage(1);
        }
    }, [pages, currentPage]);

    useEffect(() => {
        // Fetch songs based on the current page
        const searchQuery = search ? encodeURIComponent(searchBar) : '';
        const url = `${appURL}/songList?query=${searchQuery}&page=${currentPage}`;

        fetch(url)
            .then((response) => response.json())
            .then((data) => setSongs(data))
            .catch((error) => console.error('Error fetching songs:', error));
    }, [currentPage, totalSongs, selectedEntries, search, searchBar]);



    // Function to handle page changes
    const handlePageChange = (page) => {
        if (page >= 1 && page <= pages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className='song-list-container'>
            <Controls
                {...controlProps}
            />
            {(insert || edit) && <NewEntryField
                newEntry={newEntry}
                setNewEntry={setNewEntry}
                totalSongs={totalSongs}
                setTotalSongs={setTotalSongs}
                edit={edit}
                setEdit={setEdit}
                insert={insert}
                setInsert={setInsert}
                selectedEntries={selectedEntries}
                setSelectedEntries={setSelectedEntries}
            />}
            <div className='scrollable-list'>
                <List className='song-list'>
                    {songs.map((song) => (
                        <ListItem
                            key={song._id}
                            className={`song-item ${(remove && selectedEntries.includes(song._id))
                                ? 'selected'
                                : (edit && selectedEntries.includes(song._id))
                                    ? 'editing'
                                    : ''}`} // Apply your CSS class here
                            style={{
                                cursor: (remove || edit) ? 'pointer' : 'default',
                            }}
                            onClick={() => handleEntryClick(song)}
                        >
                            <ListItemText
                                primary={song.songName}
                                secondary={`${song.singer}, ${song.language}, ${song.style}, ${song.mark}`}
                            />
                        </ListItem>
                    ))}
                </List>
                {/* Render Pagination Component */}

            </div>
            <CustomPagination
                totalPages={pages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
            <HintSystem ref={hintRef}/>
        </div>
    );
}

export default SongList;