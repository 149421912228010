import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Snackbar, Alert } from '@mui/material';


const HintSystem = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
  
    // Expose the showHint function through the ref
    useImperativeHandle(ref, () => ({
      showHint(hintMessage) {
        setMessage(hintMessage);
        setOpen(true);
      },
    }));
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };
  
    return (
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    );
  });
  

export default HintSystem;