import { useState } from 'react';
import './App.css';
import SongList from './components/songList/songList';
import { Box, Button } from '@mui/material';
import LoginComponent from './components/login/loginComp';
import UploadComp from './components/upload/uploadComp';

function App() {

    // state variables to change the states
    const [view, setView] = useState(0);
    // 0: not login, 1: normal User, 2: super User
    const [loginStatus, setLoginStatus] = useState(0);
    // state variable to change the pages (modify in backend)

    return (
        <div className="container">

            <Box className='banner'
                sx={{
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 2,
                    position: 'relative', // Important for positioning the buttons
                    mb: 3, // Margin bottom to separate it from other elements
                    borderBottomLeftRadius: 8
                }}
            >
                <video
                    src={require('./components/assets/202406-6s.mp4')}
                    autoPlay
                    loop
                    muted
                    playsInline
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        position: 'absolute',
                        objectPosition: '50% 32%', // Displays the top part of the video
                        top: 0,
                        left: 0,
                        borderRadius: '8px', // Apply rounded corners
                        pointerEvents: 'none'
                    }}
                />
                {/* Container for the buttons */}
                <Box
                    sx={{
                        position: 'absolute', // Allows us to position the box relative to the banner
                        bottom: 0, // Distance from the bottom of the banner
                        left: 0, // Distance from the left of the banner
                        display: 'flex',
                        gap: 0, // Space between buttons
                        width: '100%', // Full width
                        maxWidth: '1100px', // Maximum width restriction
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            height: '50px',
                            width: 'calc(100% / 3)',
                            backgroundColor: 'rgba(255, 255, 255, 0.2)', // 20% transparency
                            //backdropFilter: 'blur(0px)', // Initial blur effect
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 8,
                            transition: 'all 0.3s ease', // Smooth transition effect
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.4)', // Slightly less transparent on hover
                                backdropFilter: 'blur(5px)', // Increase blur on hover
                            },
                        }}
                        onClick={() => setView(0)}
                    >
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            height: '50px',
                            width: 'calc(100% / 3)',
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            // backdropFilter: 'blur(0px)',
                            borderRadius: 0,
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                backdropFilter: 'blur(5px)',
                            },
                        }}
                        onClick={() => setView(1)}
                    >
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            height: '50px',
                            width: 'calc(100% / 3)',
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            borderRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 8,
                            transition: 'all 0.3s ease',
                            fontSize: '16px',                  // Change font size
                            fontFamily: 'Arial, sans-serif',    // Change font family
                            fontWeight: 'bold',                 // Change font weight
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                backdropFilter: 'blur(5px)',
                            },
                        }}
                        onClick={() => setView(2)}
                    >
                    </Button>                </Box>
            </Box>

            {view === 0 && (
                <SongList
                    login={loginStatus}
                    setLogin={setLoginStatus}
                />
            )}

            {view === 1 && (
                <LoginComponent
                    login={loginStatus}
                    setLogin={setLoginStatus} />
            )}

            {view === 2 && (
                <UploadComp />
            )}
        </div>
    );
}

export default App;
