

import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { appURL } from '../constants';

import './loginComp.css';
import HintSystem from '../hintBar';

function LoginComponent({ login, setLogin }) {
  // State for input fields
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const hintRef = React.useRef();

  // Function to handle login
  const handleLogin = async () => {
    try {
      const response = await fetch(`${appURL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setLogin(data.auth);
        console.log('Login Successful:', data.message, login);
        // change the hint message. 2024-11-13
        hintRef.current.showHint(`欢迎回到拉莱耶的小窝，${username}~`)
        // You can update your state based on the response, such as setting the login status
      } else {
        const errorData = await response.json();
        console.error('Login Failed:', errorData.message);
        // Show an error message to the user or handle it accordingly
      }
    } catch (error) {
      console.error('Error during login request:', error);
      // Handle error if the request fails
    }
  };

  return (
    <Box className='login-container'>
      <TextField
        sx={{
          width: '40%', minWidth: '200px'
        }}
        label="账号"
        variant="outlined"
        fullWidth
        margin="normal"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <TextField
        sx={{
          width: '40%', minWidth: '200px'
        }}
        label="密码"
        variant="outlined"
        type="password"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleLogin}
        fullWidth
        sx={{
          mt: 2,
          width: '20%',
          minWidth: '100px'
        }}
      >
        登录
      </Button>
      <HintSystem ref={hintRef} />
    </Box>
  );
}

export default LoginComponent;