import React from 'react';
import { inputDefault } from '../constants';
import { convertToPinyin } from '../constants';

import { appURL } from '../constants';


function NewEntryField({ newEntry, setNewEntry, totalSongs, setTotalSongs,
    edit, setEdit, insert, setInsert, selectedEntries, setSelectedEntries
}) {

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewEntry((prevEntry) => ({
            ...prevEntry,
            [name]: value,
        }));
    };

    // mongoDB operation to insert a new entry
    const handleAddSong = async () => {
        if (newEntry.songName === '') {
            return;
        }
        try {
            // Generate Pinyin fields
            const pinyinSongName = convertToPinyin(newEntry.songName);
            const pinyinSinger = convertToPinyin(newEntry.singer);

            const response = await fetch(`${appURL}/songList/insert`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    {
                        ...newEntry,
                        pinyinSongName,
                        pinyinSinger,
                    }
                ),
            });
            if (response.ok) {
                const result = await response.json();
                console.log(result.message);
                setTotalSongs((prev) => prev + 1);
                // Optionally, you can reset the form or update the song list here
                setNewEntry(inputDefault);
            } else {
                console.error('Failed to insert the song');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEdit = async () => {
        // In this case the selectedEntries should contain a single element [id]
        if (newEntry.songName === '') {
            return;
        }
        try {
            // Generate Pinyin fields
            const pinyinSongName = convertToPinyin(newEntry.songName);
            const pinyinSinger = convertToPinyin(newEntry.singer);

            const response = await fetch(`${appURL}/songList/edit`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: selectedEntries[0],
                    ...newEntry, 
                    // Spread the new entry details here
                    pinyinSongName,
                    pinyinSinger
                }),
            });

            if (response.ok) {
                const result = await response.json();
                console.log(result.message);
                // Optionally, refresh the list or update the state after editing
                setNewEntry(inputDefault);
                // Empty the current selected entries.
                setSelectedEntries([]);
            } else {
                console.error('Failed to update the entry', response.body);
            }
        } catch (error) {
            console.error('Error updating the entry:', error);
        }
    }

    const handleCancel = () => {
        setNewEntry(inputDefault);
        if (edit) {
            setSelectedEntries([]);
        }
    }

    return (
        <div className="insert-form">
            <input
                type="text"
                name="songName"
                placeholder="Song Name"
                value={newEntry.songName}
                onChange={handleInputChange}
                className="input-box"
            />
            <input
                type="text"
                name="singer"
                placeholder="Singer"
                value={newEntry.singer}
                onChange={handleInputChange}
                className="input-box"
            />
            <input
                type="text"
                name="language"
                placeholder="Language"
                value={newEntry.language}
                onChange={handleInputChange}
                className="input-box"
            />
            <input
                type="text"
                name="style"
                placeholder="Style"
                value={newEntry.style}
                onChange={handleInputChange}
                className="input-box"
            />
            <input
                type="text"
                name="mark"
                placeholder="Mark"
                value={newEntry.mark}
                onChange={handleInputChange}
                className="input-box"
            />

            <button onClick={() => {
                if (insert) {
                    handleAddSong();
                } else if (edit) {
                    handleEdit();
                }
            }}
                className="control-btn">✔️</button>
            <button onClick={handleCancel}
                className="control-btn">❌</button>
        </div>
    )
}

export default NewEntryField